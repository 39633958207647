import { apiWithToken } from "@/vuex/modules/authentication/axios/base"
const state = () => ({
  loading: false,
})
let token = localStorage.getItem("authToken")
let businessId = localStorage.getItem("businessId")

const mutations = {
  setLoading(state, loading) {
    state.loading = loading
  },
}

const getters = {
  getLoading: (state) => state.loading,
}

const actions = {
  async getTransactions({ commit }) {
    return apiWithToken(token)
      .get(`payfast/${businessId}/business`)
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
