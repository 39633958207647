import Styled from "vue3-styled-components"

const Container = Styled.div`
  top: 50px;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 99999;

`

const Wrapper = Styled("div", { type: "error" | "info" | "success" })`
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  left: 0;
  margin: 0 auto;
  max-width: fit-content;
  right: 0;
  padding: 15px 5px 0 15px;
  top: 30px;

  .ant-alert {
    background-color: transparent;
    border: none;
    line-height: 1;
    padding: 0 10px 15px 15px;

    .ant-alert-description {
      opacity: 90%;
    }
  }

  .anticon {
    font-size: 14px;
  }

  .ant-alert-close-icon {
    cursor: pointer;
    font-size: 16px;
    margin-left: 40px;
  }

  .anticon:not(.anticon-close) svg {
    fill: ${({ type }) => {
      switch (type) {
        case "info":
          return "#1890FF"
        case "error":
          return "#BA2C25"
        case "success":
          return "#519120"
        default:
          return "#FFF"
      }
    }}
  }
`

export { Container, Wrapper }
