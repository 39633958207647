<script>
import { AutoCompleteStyled } from "./style"
import VueTypes from "vue-types"
import { toRefs, ref, computed, defineComponent } from "vue"
import { useStore } from "vuex"

export default defineComponent({
  name: "AutoComplete",
  components: {
    AutoCompleteStyled,
  },
  props: {
    customComponent: VueTypes.bool.def(false),
    patterns: VueTypes.bool.def(false),
    patternButtons: VueTypes.bool.def(false),
    width: VueTypes.string.def("350px"),
    dataSource: VueTypes.array,
    placeholder: VueTypes.string.def("Input here"),
  },
  setup(props) {
    const { dataSource } = toRefs(props)
    const { state } = useStore()
    const value = ref("")
    const myData = ref(dataSource.value)
    const rtl = computed(() => state.themeLayout.rtlData)

    const filterOption = (input, option) => {
      return option.value.toUpperCase().startsWith(input.toUpperCase())
    }

    const onSelect = () => {}

    return {
      value,
      myData,
      rtl,
      onSelect,
      filterOption,
    }
  },
})
</script>

<template>
  <AutoCompleteStyled
    v-if="customComponent"
    :style="{ width }"
    :options="myData"
    :filter-option="filterOption"
    @select="onSelect"
  >
    <slot></slot>
  </AutoCompleteStyled>
  <AutoCompleteStyled
    v-else-if="patterns"
    v-model:value="value"
    class="certain-category-search"
    dropdown-class-name="certain-category-search-dropdown"
    :dropdown-match-select-width="false"
    :dropdown-style="{ width: 300 }"
    :style="{ width }"
    :placeholder="placeholder"
    :options="myData"
    :filter-option="filterOption"
  >
    <a-input>
      <template #suffix>
        <sdButton
          v-if="patternButtons"
          class="search-btn"
          :style="{ [rtl ? 'marginLeft' : 'marginRight']: -20 }"
          type="primary"
        >
          <unicon name="search"></unicon>
        </sdButton><unicon
          v-else
          name="search"
        ></unicon>
      </template>
    </a-input>
  </AutoCompleteStyled>

  <AutoCompleteStyled
    v-else
    :style="{ width }"
    :placeholder="placeholder"
    :value="value"
    :options="myData"
    :filter-option="filterOption"
    @select="onSelect"
  ></AutoCompleteStyled>
</template>
