import mutations from "./mutations"

const state = () => ({
  alert: null,
  isBusy: false,
})

const getters = {
  getAlert: (state) => state.alert,
}

const actions = {
  async alert({ commit }, params) {
    try {
      commit("alertSet", params)
    } catch (err) {
      commit("alertSet", {
        message: "Something went wrong",
        description: "Please try again later",
        type: "error",
      })
    }
  },
  async clearAlert({ commit }) {
    try {
      commit("alertClear")
    } catch (err) {
      state.alert = null
    }
  },
  async busy({ commit }) {
    commit("setBusy", true)
  },
  async done({ commit }) {
    commit("setBusy", false)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
