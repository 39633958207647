<script>
import { onMounted, ref, defineComponent } from "vue"
import { CustomDropDown } from "./styled"
import { Calendar } from "v-calendar"
import "v-calendar/dist/style.css"
export default defineComponent({
  name: "CalendarButton",
  components: {
    CustomDropDown,
    VCalendar: Calendar,
  },
  setup() {
    const isVisible = ref(false)
    const handleVisible = () => {
      isVisible.value = !isVisible.value
    }

    onMounted(() => {
      document.body.addEventListener("click", (e) => {
        if (e.target.closest("#dropdownParent")) {
          return false
        } else {
          isVisible.value = false
        }
      })
    })

    return {
      handleVisible,
      isVisible,
    }
  },
  data() {
    return {
      range: {
        start: new Date(2021, 0, 1),
        end: new Date(2021, 0, 5),
      },
    }
  },
})
</script>

<template>
  <CustomDropDown id="dropdownParent">
    <div
      v-if="isVisible"
      class="easydoc-range-calendar"
      placement="bottomRight"
      title="Search by Calendar"
      action="click"
    >
      <v-calendar
        v-model="range"
        :columns="$screens({ default: 1, lg: 2 })"
        is-expanded
        is-range
      ></v-calendar>
    </div>
    <sdButton
      size="sm"
      type="white"
      @click="handleVisible"
    >
      <unicon
        name="calendar-alt"
        width="14"
      ></unicon>
      <span>Calendar</span>
    </sdButton>
  </CustomDropDown>
</template>

<style>
.ant-page-header-heading {
  overflow: visible;
}
</style>
