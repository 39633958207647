<script>
import { defineComponent } from "vue"
import VueTypes from "vue-types"

const Loader = defineComponent({
  name: "Loader",
  props: {
    label: VueTypes.string,
    loading: VueTypes.bool.def(false),
    size: VueTypes.oneOf(["small", "large"]).def("small"),
  },
})

export default Loader
</script>

<template>
  <template v-if="loading">
    <a-spin :size="size"></a-spin>
  </template>
  <template v-else>
    {{ label }}
  </template>
</template>
