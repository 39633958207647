export default [
  {
    path: "/app/chat",
    name: "chat",
    component: () =>
      import(/* webpackChunkName: "chat" */ "../view/apps/chat/ChatApp.vue"),
    children: [
      {
        name: "private",
        path: "private",
        component: () =>
          import(
            /* webpackChunkName: "private" */ "../view/apps/chat/overview/PrivetChat.vue"
          ),
      },
      {
        name: "privateSingle",
        path: "private/:id",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "private" */ "../view/apps/chat/overview/PrivetChat.vue"
            ),
          child: () =>
            import(
              /* webpackChunkName: "privateSingle" */ "../view/apps/chat/overview/SingleChat.vue"
            ),
        },
      },
      {
        name: "group",
        path: "group",
        component: () =>
          import(
            /* webpackChunkName: "group" */ "../view/apps/chat/overview/GroupChat.vue"
          ),
      },
      {
        name: "groupSingle",
        path: "group/:id",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "private" */ "../view/apps/chat/overview/GroupChat.vue"
            ),
          child: () =>
            import(
              /* webpackChunkName: "privateSingle" */ "../view/apps/chat/overview/SingleGroupChat.vue"
            ),
        },
      },
      {
        name: "all",
        path: "all",
        component: () =>
          import(
            /* webpackChunkName: "all" */ "../view/apps/chat/overview/AllContacts.vue"
          ),
      },
      {
        name: "allSingle",
        path: "all/:id",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "private" */ "../view/apps/chat/overview/AllContacts.vue"
            ),
          child: () =>
            import(
              /* webpackChunkName: "privateSingle" */ "../view/apps/chat/overview/SingleChat.vue"
            ),
        },
      },
    ],
  },
  {
    name: "calendar",
    path: "/app/calendar",
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ "../view/apps/calendar/Calendar.vue"
      ),
    children: [
      {
        name: "year",
        path: "year",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "calendar" */ "../view/apps/calendar/Calendar.vue"
            ),
          child: () =>
            import(
              /* webpackChunkName: "year" */ "../view/apps/calendar/overview/Year.vue"
            ),
        },
      },
      {
        name: "month",
        path: "month",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "calendar" */ "../view/apps/calendar/Calendar.vue"
            ),
          child: () =>
            import(
              /* webpackChunkName: "month" */ "../view/apps/calendar/overview/Month.vue"
            ),
        },
      },
      {
        name: "week",
        path: "week",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "calendar" */ "../view/apps/calendar/Calendar.vue"
            ),
          child: () =>
            import(
              /* webpackChunkName: "week" */ "../view/apps/calendar/overview/Week.vue"
            ),
        },
      },
      {
        name: "day",
        path: "day",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "calendar" */ "../view/apps/calendar/Calendar.vue"
            ),
          child: () =>
            import(
              /* webpackChunkName: "day" */ "../view/apps/calendar/overview/Day.vue"
            ),
        },
      },
      {
        name: "schedule",
        path: "schedule",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "calendar" */ "../view/apps/calendar/Calendar.vue"
            ),
          child: () =>
            import(
              /* webpackChunkName: "schedule" */ "../view/apps/calendar/overview/Schedule.vue"
            ),
        },
      },
    ],
  },
  {
    name: "team",
    path: "/admin/x-team",
    component: () =>
      import(/* webpackChunkName: "users" */ "../view/apps/users/Index.vue"),
    children: [
      {
        path: "users",
        name: "users-1",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "users" */ "../view/apps/users/Index.vue"
            ),
          child: () =>
            import(
              /* webpackChunkName: "users-1" */ "../view/apps/users/Users.vue"
            ),
        },
        children: [
          {
            path: "user-grid",
            name: "user-grid",
            components: {
              default: () =>
                import(
                  /* webpackChunkName: "users" */ "../view/apps/users/Index.vue"
                ),
              descendant: () =>
                import(
                  /* webpackChunkName: "user-grid" */ "../view/apps/users/overview/UserCard.vue"
                ),
            },
          },
          {
            path: "user-list",
            name: "user-list",
            components: {
              default: () =>
                import(
                  /* webpackChunkName: "users" */ "../view/apps/users/Index.vue"
                ),
              descendant: () =>
                import(
                  /* webpackChunkName: "user-list" */ "../view/apps/users/overview/UserCardList.vue"
                ),
            },
          },
          {
            path: "grid-style",
            name: "grid-style",
            components: {
              default: () =>
                import(
                  /* webpackChunkName: "users" */ "../view/apps/users/Index.vue"
                ),
              descendant: () =>
                import(
                  /* webpackChunkName: "user-style" */ "../view/apps/users/overview/UserCardStyle.vue"
                ),
            },
          },
          {
            path: "grid-group",
            name: "grid-group",
            components: {
              default: () =>
                import(
                  /* webpackChunkName: "users" */ "../view/apps/users/Index.vue"
                ),
              descendant: () =>
                import(
                  /* webpackChunkName: "user-group" */ "../view/apps/users/overview/UserCardGroup.vue"
                ),
            },
          },
        ],
      },
      {
        path: "add-user",
        name: "add-user",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "users" */ "../view/apps/users/Index.vue"
            ),
          child: () =>
            import(
              /* webpackChunkName: "addUser" */ "../view/apps/users/AddUsers.vue"
            ),
        },
        children: [
          {
            path: "info",
            name: "info",
            components: {
              default: () =>
                import(
                  /* webpackChunkName: "users" */ "../view/apps/users/Index.vue"
                ),
              descendant: () =>
                import(
                  /* webpackChunkName: "info" */ "../view/apps/users/overview/Info.vue"
                ),
            },
          },
          {
            path: "work",
            name: "work",
            components: {
              default: () =>
                import(
                  /* webpackChunkName: "users" */ "../view/apps/users/Index.vue"
                ),
              descendant: () =>
                import(
                  /* webpackChunkName: "work" */ "../view/apps/users/overview/work.vue"
                ),
            },
          },
          {
            path: "social",
            name: "social-user",
            components: {
              default: () =>
                import(
                  /* webpackChunkName: "users" */ "../view/apps/users/Index.vue"
                ),
              descendant: () =>
                import(
                  /* webpackChunkName: "social-user" */ "../view/apps/users/overview/Social.vue"
                ),
            },
          },
        ],
      },
    ],
  },
  {
    name: "contact",
    path: "/app/contact",
    component: () =>
      import(
        /* webpackChunkName: "contact-grid" */ "../view/apps/contact/Index.vue"
      ),
    children: [
      {
        name: "contact-grid",
        path: "contact-grid",
        component: () =>
          import(
            /* webpackChunkName: "contact-grid" */ "../view/apps/contact/ContactGrid.vue"
          ),
      },
      {
        name: "contact-list",
        path: "contact-list",
        component: () =>
          import(
            /* webpackChunkName: "contact-list" */ "../view/apps/contact/Contact.vue"
          ),
      },
      {
        name: "contact-create",
        path: "contact-create",
        component: () =>
          import(
            /* webpackChunkName: "contact-create" */ "../view/apps/contact/ContactCreate.vue"
          ),
      },
    ],
  },
  {
    name: "note",
    path: "/app/note",
    component: () =>
      import(/* webpackChunkName: "note" */ "../view/apps/note/Note.vue"),
    children: [
      {
        path: "all",
        name: "note-all",
        components: {
          default: () =>
            import(/* webpackChunkName: "note" */ "../view/apps/note/Note.vue"),
          child: () =>
            import(
              /* webpackChunkName: "note-all" */ "../view/apps/note/overview/all.vue"
            ),
        },
      },
      {
        path: "favorite",
        name: "note-favorite",
        components: {
          default: () =>
            import(/* webpackChunkName: "note" */ "../view/apps/note/Note.vue"),
          child: () =>
            import(
              /* webpackChunkName: "note-favorite" */ "../view/apps/note/overview/favorite.vue"
            ),
        },
      },
      {
        path: "personal",
        name: "note-personal",
        components: {
          default: () =>
            import(/* webpackChunkName: "note" */ "../view/apps/note/Note.vue"),
          child: () =>
            import(
              /* webpackChunkName: "note-personal" */ "../view/apps/note/overview/personal.vue"
            ),
        },
      },
      {
        path: "work",
        name: "note-work",
        components: {
          default: () =>
            import(/* webpackChunkName: "note" */ "../view/apps/note/Note.vue"),
          child: () =>
            import(
              /* webpackChunkName: "note-work" */ "../view/apps/note/overview/work.vue"
            ),
        },
      },
      {
        path: "social",
        name: "social-note",
        components: {
          default: () =>
            import(/* webpackChunkName: "note" */ "../view/apps/note/Note.vue"),
          child: () =>
            import(
              /* webpackChunkName: "social-note" */ "../view/apps/note/overview/social.vue"
            ),
        },
      },
      {
        path: "important",
        name: "note-important",
        components: {
          default: () =>
            import(/* webpackChunkName: "note" */ "../view/apps/note/Note.vue"),
          child: () =>
            import(
              /* webpackChunkName: "important" */ "../view/apps/note/overview/important.vue"
            ),
        },
      },
    ],
  },
  {
    name: "to-do",
    path: "/app/to-do",
    component: () =>
      import(/* webpackChunkName: "to-do" */ "../view/apps/todo/Todo.vue"),
  },
  {
    name: "import-export",
    path: "/app",
    component: () =>
      import(
        /* webpackChunkName: "import" */ "../view/apps/importExport/Index.vue"
      ),
    children: [
      {
        name: "import",
        path: "import",
        component: () =>
          import(
            /* webpackChunkName: "import" */ "../view/apps/importExport/Import.vue"
          ),
      },
      {
        name: "export",
        path: "export",
        component: () =>
          import(
            /* webpackChunkName: "export" */ "../view/apps/importExport/Export.vue"
          ),
      },
    ],
  },
  {
    name: "task",
    path: "/app/task",
    component: () =>
      import(/* webpackChunkName: "task" */ "../view/apps/task/Index.vue"),
    children: [
      {
        name: "all",
        path: "all",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "task" */ "../view/apps/task/Index.vue"
            ),
          child: () =>
            import(
              /* webpackChunkName: "all" */ "../view/apps/task/overview/all.vue"
            ),
        },
      },
      {
        name: "favorites",
        path: "favorites",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "task" */ "../view/apps/task/Index.vue"
            ),
          child: () =>
            import(
              /* webpackChunkName: "favorites" */ "../view/apps/task/overview/favorites.vue"
            ),
        },
      },
      {
        name: "completed",
        path: "completed",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "task" */ "../view/apps/task/Index.vue"
            ),
          child: () =>
            import(
              /* webpackChunkName: "completed" */ "../view/apps/task/overview/completed.vue"
            ),
        },
      },
    ],
  },
  {
    name: "kanban",
    path: "/app/kanban",
    component: () =>
      import(/* webpackChunkName: "kanban" */ "../view/apps/kanban/Index.vue"),
  },
  {
    name: "tickets",
    path: "/app/support/tickets",
    component: () =>
      import(
        /* webpackChunkName: "tickets" */ "../view/apps/supportTicket/SickNotes.vue"
      ),
  },
  {
    name: "ticketDetails",
    path: "/app/support/ticketDetails/:id",
    component: () =>
      import(
        /* webpackChunkName: "ticketDetails" */ "../view/apps/supportTicket/SickNoteDetails.vue"
      ),
  },
  {
    name: "learning",
    path: "/app/learning/course",
    component: () =>
      import(/* webpackChunkName: "course" */ "../view/apps/course/Course.vue"),
  },
  {
    name: "courseDetails",
    path: "/app/learning/courseDetails/:id",
    component: () =>
      import(
        /* webpackChunkName: "course" */ "../view/apps/course/CourseDetails.vue"
      ),
  },
]
