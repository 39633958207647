<script>
import { H1, H2, H3, H4, H5, H6 } from "./style"
import VueTypes from "vue-types"
import { defineComponent } from "vue"

export default defineComponent({
  name: "Heading",
  props: {
    as: VueTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]).def("h1"),
    className: VueTypes.string,
    id: VueTypes.string,
    styles: VueTypes.object.def({}),
  },
  components: {
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
  },
})
</script>

<template>
  <H1
    v-if="as.toUpperCase() === 'H1'"
    :id="id"
    :style="styles"
    :class-name="className"
  >
    <slot></slot>
  </H1>
  <H2
    v-else-if="as.toUpperCase() === 'H2'"
    :id="id"
    :style="styles"
    :class-name="className"
  >
    <slot></slot>
  </H2>
  <H3
    v-else-if="as.toUpperCase() === 'H3'"
    :id="id"
    :style="styles"
    :class-name="className"
  >
    <slot></slot>
  </H3>
  <H4
    v-else-if="as.toUpperCase() === 'H4'"
    :id="id"
    :style="styles"
    :class-name="className"
  >
    <slot></slot>
  </H4>
  <H5
    v-else-if="as.toUpperCase() === 'H5'"
    :id="id"
    :style="styles"
    :class-name="className"
  >
    <slot></slot>
  </H5>
  <H6
    v-else
    :id="id"
    :style="styles"
    :class-name="className"
  >
    <slot></slot>
  </H6>
</template>
