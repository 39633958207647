<script>
import { CardFrame } from "./style"
import VueTypes from "vue-types"
import { defineComponent } from "vue"
export default defineComponent({
  name: "Cards",
  components: {
    CardFrame,
  },
  props: {
    title: VueTypes.oneOfType([
      VueTypes.string,
      VueTypes.object,
      VueTypes.node,
    ]),
    size: VueTypes.oneOf(["default", "small"]).def("default"),
    more: VueTypes.bool.def(false),
    bodyStyle: VueTypes.object,
    headStyle: VueTypes.object,
    headless: VueTypes.bool.def(false),
    border: VueTypes.bool.def(false),
    caption: VueTypes.string,
    bodypadding: VueTypes.string,
    moreText: VueTypes.bool.def(false),
  },
})
</script>

<template>
  <CardFrame
    v-if="!headless"
    :size="size"
    :title="title"
    :body-style="bodyStyle && bodyStyle"
    :head-style="headStyle && headStyle"
    :bordered="border"
    :bodypadding="bodypadding && bodypadding"
    :style="{ width: '100%' }"
  >
    <template #title>
      <slot name="title"></slot>
    </template>
    <slot name="caption"></slot>
    <template #extra>
      <slot name="button"></slot>
      <sdDropdown
        v-if="more"
        :action="['click']"
        placement="bottomRight"
      >
        <template #overlay>
          <slot name="more"></slot>
        </template>
        <router-link
          v-if="!moreText"
          class="card-extra-link"
          to="#"
        >
          <unicon
            name="ellipsis-h"
            width="24"
          ></unicon>
        </router-link>
        <router-link
          v-else
          to="#"
        >
          More
        </router-link>
      </sdDropdown>
    </template>
    <slot></slot>
  </CardFrame>

  <CardFrame
    v-else
    :bodypadding="bodypadding && bodypadding"
    :body-style="bodyStyle && bodyStyle"
    :size="size"
    :style="{ width: '100%' }"
    :bordered="border"
    :headless="true"
  >
    <sdHeading
      v-if="title"
      as="h4"
    >
      {{ title }}
    </sdHeading>
    <p v-if="caption">
      {{ caption }}
    </p>
    <slot></slot>
  </CardFrame>
</template>
