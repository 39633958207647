import "./static/css/style.css"
// Vue 3rd party plugins
import "@/core/plugins/ant-design"
import "@/core/plugins/fonts"
import "@/core/plugins/maps"
import "@/core/plugins/masonry"
import "@/core/plugins/apexcharts"
import "@/core/plugins/unicons"
import "@/core/components/custom"
import "@/core/components/style"
import "@/i18n/config"

import Vue3Lottie from "vue3-lottie"
import app from "./config/configApp"
import router from "./routes/protectedRoute"
import store from "@/vuex/store"

app.config.productionTip = false
app.use(store)
app.use(router)
app.use(Vue3Lottie)
app.mount("#app")
