export default {
  lightMode: {
    "main-background": "#F4F4FC",
    "main-background-light": "#F8F9FB",
    "main-background-light-dark": "#f4f5f7",
    "dark-background": "#EFF0F3",
    "dark-background2": "#0A0A0A",
    "darker-background": "#202444",
    "darker-background2": "#ffffff",
    "white-background": "#FFFFFF",
    "general-background": "#F8F9FD",
    "brand-background": "#FFFFFF",
    "status-background": "#F8F9FB",
    "primary-trasparent": "#FFEAF3",
    "light-background": "#F8F9FB",
    "light-border": "#F1F2F6",
    "white-text": "#ffffff",
    "gray-text": "#404040",
    "gray-lighter-text": "#404040",
    "gray-light-text": "#8C90A4",
    "dark-text": "#0A0A0A",
    "light-text": "#8C90A4",
    "extra-light-text": "#ADB4D2",
    "light-gray-text": "#404040",
    "extra-light": "#ADB5D9",
    "border-color-default": "#F1F2F6",
    "border-color-checkbox": "#C6D0DC",
    "border-color-secondary": "#E3E6EF",
    "border-color-deep": "#C6D0DC",
    "menu-icon-color": "#ADB5D9",
    "menu-active": "#302b70",
    "menu-active-bg": "#fff",
    "scroll-bg": "#E3E6EF",
    "chat-bg": "#EFF0F3",
    "chat-reply-bg": "#787cd1",
    "chat-reply-text": "#ffffff",
    "input-bg": "#ffffff",
    "primary-white": "#302b70",
    "secondary-white": "#787cd1",
    "badge-example-bg": "#ddd",
    "primary-white-15": "#302b7015",
  },
  blackMode: {
    "main-background": "#010413",
    "main-background-light": "#282B37",
    "main-background-light-dark": "#16181f",
    "dark-background": "#282B37",
    "dark-background2": "#10121b",
    "darker-background": "#1B1E2B",
    "darker-background2": "#1B1E2B",
    "light-background": "#282B37",
    "status-background": "#282B37" /* 6% */,
    "primary-transparent": "#323541",
    "white-background": "#1B1E2B",
    "general-background": "#323541",
    "brand-background": "#323541",
    "light-border": "#282B37",
    "white-text": "#1B1E2B",
    "gray-text": "#A4A5AA" /* 60% */,
    "gray-lighter-text": "#E1E1E3" /* 87% of #fff */,
    "gray-light-text": "#A4A5AA",
    "dark-text": "#E1E1E3" /* 87% */,
    "light-text": "#4D4F5A" /* 30% */,
    "extra-light-text": "#A4A6AB",
    "light-gray-text": "#E1E1E3",
    "extra-light": "#ADAEB3",
    "border-color-default": "#323541",
    "border-color-checkbox": "#A4A6AB",
    "border-color-secondary": "#494B55",
    "border-color-deep": "#070A19",
    "menu-icon-color": "#A4A5AA",
    "menu-active": "#ffffff",
    "menu-active-bg": "#282B37",
    "scroll-bg": "#565A65" /* 30% */,
    "chat-bg": "#282B37",
    "chat-reply-bg": "#282B37",
    "chat-reply-text": "#A4A5AA",
    "input-bg": "#282B37",
    "primary-white": "#ffffff",
    "secondary-white": "#ffffff",
    "badge-example-bg": "#10121b",
    "primary-white-15": "#ffffff10",
  },
}
