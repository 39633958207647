import { redirectIfNecessary } from "@/routes/authguard"

const routes = [
  {
    path: "/",
    alias: "",
    name: "index",
    component: () => import("@/view/pages/index.vue"),
  },
  {
    path: "client-onboarding",
    name: "client-onboarding",
    component: () => import("@/view/pages/ClientOnboarding.vue"),
  },
  {
    path: "for-me",
    name: "forme",
    component: () => import("@/pages/SignupPage.vue"),
  },
  {
    path: "for-me/:eId/confirm",
    name: "forme-confirm",
    component: () => import("@/pages/IndividualPricing.vue"),
    props: true,
  },
  {
    path: "/get-started",
    name: "create-account",
    component: () =>
      import(/* webpackChunkName: "create" */ "@/pages/CreateAccount.vue"),
    beforeEnter: redirectIfNecessary,
  },
  {
    path: "/get-started/:eId",
    name: "resume-create-account",
    component: () =>
      import(/* webpackChunkName: "create" */ "@/pages/CreateAccount.vue"),
    props: true,
    beforeEnter: redirectIfNecessary,
  },
  {
    path: "/partner/:slug",
    name: "member-register",
    props: true,
    component: () =>
      import(/* webpackChunkName: "branded" */ "@/pages/CompanyPage.vue"),
  },
]

export default routes
