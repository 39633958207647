import {
  BubbleChart,
  Google3dPieChart,
  GoogleBasicBarChart,
  GoogleBasicPieChart,
  GoogleComboChart,
  GoogleCustomColorChart,
  GoogleLineChart,
  GoogleMaterialBarChart,
  GoogleMultiLineChart,
  GoogleStackedChart,
} from "../../components/charts/googleChart.vue"

import Alerts from "../../components/alerts/Alerts.vue"
import AutoComplete from "../../components/autoComplete/autoComplete"
import BtnGroup from "../../components/buttons/ButtonGroup.vue"
import Button from "../../components/buttons/Buttons.vue"
import CalendarButton from "../../components/buttons/CalendarButton.vue"
import Cards from "../../components/cards/frame/CardsFrame.vue"
import Cascader from "../../components/cascader/Cascader"
import { ChartContainer } from "../../view/dashboard/style"
import Drawer from "../../components/drawer/Drawer.vue"
import Dropdown from "../../components/dropdown/Dropdown"
import ExportButton from "../../components/buttons/ExportButton.vue"
import FeatherIcons from "../../components/utilities/featherIcons.vue"
import Heading from "../../components/heading/Heading"
import Loader from "../../components/loader/Loader.vue"
import Modal from "../../components/modals/Modals"
import { PageHeader } from "../../components/pageHeaders/PageHeaders.vue"
import Popover from "../../components/popup/Popup"
import ShareButton from "../../components/buttons/ShareButton.vue"
import Toast from "../../components/toast/Toast.vue"
import app from "../../config/configApp"
;[
  Heading,
  Cards,
  {
    name: "ChartContainer",
    ...ChartContainer,
  },

  {
    name: "GoogleMaterialBarChart",
    ...GoogleMaterialBarChart,
  },
  {
    name: "GoogleBasicBarChart",
    ...GoogleBasicBarChart,
  },
  { name: "GoogleStackedChart", ...GoogleStackedChart },
  { name: "GoogleCustomColorChart", ...GoogleCustomColorChart },
  { name: "GoogleComboChart", ...GoogleComboChart },
  { name: "GoogleLineChart", ...GoogleLineChart },
  { name: "GoogleMultiLineChart", ...GoogleMultiLineChart },
  { name: "GoogleBasicPieChart", ...GoogleBasicPieChart },
  { name: "Google3dPieChart", ...Google3dPieChart },
  { name: "BubbleChart", ...BubbleChart },

  Alerts,
  {
    name: "Button",
    ...Button,
  },
  {
    name: "BtnGroup",
    ...BtnGroup,
  },
  Drawer,
  CalendarButton,
  ExportButton,
  ShareButton,
  { name: "PageHeader", ...PageHeader },
  Cascader,
  AutoComplete,
  Dropdown,
  Popover,
  FeatherIcons,
  Modal,
  Toast,
  Loader,
].map((c) => {
  app.component(`sd${c.name}`, c)
})
