const routes = [
  {
    path: "login",
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@/view/authentication/SignIn.vue"
      ),
  },
  {
    path: "register",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "@/view/authentication/Signup.vue"
      ),
  },
  {
    path: "forgot-password",
    name: "forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "@/view/authentication/ForgotPassword.vue"
      ),
  },
  {
    path: "reset-password",
    name: "reset-password",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "@/view/authentication/ResetPassword.vue"
      ),
  },
  {
    path: "accept-invite",
    name: "accept-invite",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "@/view/authentication/ResetPassword.vue"
      ),
  },
  {
    path: "/success",
    name: "success",
    component: () =>
      import(/* webpackChunkName: "Blank" */ "@/pages/Success.vue"),
  },
]

export default routes
