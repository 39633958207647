<script>
import {
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled,
} from "@ant-design/icons-vue"
import { Alert } from "ant-design-vue"
import { defineComponent, onMounted } from "vue"

import { Container, Wrapper } from "./style"
import { useStore } from "vuex"

const Toast = defineComponent({
  name: "Toast",
  components: {
    Alert,
    CheckCircleFilled,
    CloseCircleFilled,
    Container,
    InfoCircleFilled,
    Wrapper,
  },
  props: {
    alert: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { dispatch } = useStore()

    onMounted(() => {
      setTimeout(() => {
        dispatch("notify/clearAlert")
      }, 3000)
    })

    const afterClose = () => {
      dispatch("notify/clearAlert")
    }

    return { afterClose }
  },
})

export default Toast
</script>

<template>
  <Container>
    <slot>
      <Wrapper
        :class="alert.type"
        :type="alert.type"
      >
        <template v-if="alert.type === 'error'">
          <CloseCircleFilled></CloseCircleFilled>
        </template>
        <template v-else-if="alert.type === 'info'">
          <InfoCircleFilled></InfoCircleFilled>
        </template>
        <template v-else-if="alert.type === 'success'">
          <CheckCircleFilled></CheckCircleFilled>
        </template>
        <Alert
          :after-close="afterClose"
          :description="alert.description"
          :message="alert.message"
          closable
        ></Alert>
      </Wrapper>
    </slot>
  </Container>
</template>
