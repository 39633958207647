import app from "../../config/configApp"
import {
  LMap,
  LTileLayer,
  LMarker,
  LControlLayers,
} from "@vue-leaflet/vue-leaflet"
import "leaflet/dist/leaflet.css"
import CKEditor from "@ckeditor/ckeditor5-vue"

app.use(CKEditor)
app.component("LMap", LMap)
app.component("LTileLayer", LTileLayer)
app.component("LMarker", LMarker)
app.component("LControlLayer", LControlLayers)
