export default {
  setBusy(state, busy) {
    state.isBusy = busy
  },

  alertSet(state, data) {
    state.alert = {
      message: data.message,
      description: data.description || "",
      type: data.type || "info",
    }
  },

  alertClear(state) {
    state.alert = null
  },
}
