import app from '../config/configApp';
import ar from './localization/ar/translation.json';
import { createI18n } from 'vue-i18n';
import en from './localization/en/translation.json';
import en_api from "./localization/en/api.json"
import esp from './localization/en/translation.json';

const i18n = createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: { ...en, ...en_api },
        ar: ar,
        esp: esp,
    },
})

app.use(i18n)
